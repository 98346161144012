html *
{
  font-family: 'Dosis', 'Assistant';
}

body {
  margin: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #474a4d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

.modal {
  position: absolute;
  top: 15vh;
  left: 2vw;
  right: 2vw;
  bottom: 10vh;
  border-radius: 1.5vw;
  background-color: rgb(13, 13, 51);
  overflow: scroll;
  transition: height 0.66 s ease - out; 
}

.modal-team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 20vh;
  left: 28vw;
  right: 28vw;
  bottom: 20vh;
  border-radius: 1.5vw;
  color: black;
  background-color: white;
  overflow: scroll;
  transition: height 0.66 s ease - out; 
}

.modal-rules {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 20vh;
  left: 28vw;
  right: 28vw;
  bottom: 20vh;
  border-radius: 1.5vw;
  color: black;
  background-color: rgb(13, 13, 51);
  overflow: scroll;
  transition: height 0.66 s ease - out; 
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.year {
  /* width: 100%; */
  margin: 30px auto;
  overflow: auto;
  border: 1px solid steelblue;
  /* padding: 5px; */
  border-radius: 10px;
}

.results {
  width: 40vw;
  margin-top: 30px;
  overflow: auto;
  padding: 1vw;
  border-radius: 1.5vw;
  background-color: rgba(255, 255, 255, 0.712);
}

.insights {
  min-height: 300px;

  width: 40vw;
  margin-top: 30px;
  overflow: auto;
  padding: 1vw;
  border-radius: 1.5vw;
  background-color: rgba(255, 255, 255, 0.712);
}

.profile-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.profile-head {
  display: flex;
  /* justify-content: center; */
  max-width: 1000px;
  margin-top: 30px;
  overflow: auto;
  height: 25vw;
  max-height: 250px;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5px;
  border-radius: 1vw;
  gap: 2.5vw;
}

.discipline-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 0.5vw;
  border-radius: 1vw;
  gap: 25px;
}

.stat-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80vw;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5px;
  border-radius: 1vw;
  margin-top: 20px;
  gap: 25px;
}

.auth-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5vh 5vw;
  border-radius: 1vw;
  margin-top: 20px;
  gap: 25px;
}

.fantasy-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80vw;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5px;
  border-radius: 1vw;
  margin-top: 20px;
  /* gap: 25px; */
}

.fantasy-draft-body {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-width: 90vw;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  border-radius: 1vw;
  /* border-style: solid; */
  margin-top: 20px;
  /* gap: 25px; */
}

.fantasy-events-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 90vw;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.712);
  padding: 5px;
  border-radius: 1vw;
  margin-top: 20px;
  /* gap: 25px; */
}

.fantasy-event-card {
  height: 20vh;
  width: 30vw;
  background: #525357;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 1vw;
}

.fantasy-event-card-title {
  margin: 0px;
  padding: 1vh;
  background: black;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fantasy-build-title {
  margin: 0px;
  margin-bottom: 3vh;
  padding: 1vh 2vw;
  background: white;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fantasy-event-card-women {
  background: palevioletred;
  width: 15vw;
  height: 100%;
  border-bottom-right-radius: 1vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: 600ms;
}

.fantasy-event-card-women:hover {
  filter: brightness(1.3);
}

.fantasy-event-card-men {
  background: steelblue;
  width: 15vw;
  height: 100%;
  border-bottom-left-radius: 1vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: 600ms;
}

.fantasy-event-card-men:hover {
  filter: brightness(1.3);
}

.discipline-icon {
  background-color: white;
  color: black;
  border-radius: 50%;
  width: 1.5vw;
  margin-left: 1vw;
  /* margin-right: 1vw; */
  display: flex;
  justify-content: center;
}

.discipline-icon-swap {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 1.5vw;
  margin-right: 0.2vw;
  display: flex;
  justify-content: center;
}

.m-icon {
  background-color: steelblue;
  color: white;
  border-radius: 50%;
  width: 1.5vw;
  margin-right: 1vw;
  display: flex;
  justify-content: center;
}

.w-icon {
  background-color: palevioletred;
  color: white;
  border-radius: 50%;
  width: 1.5vw;
  margin-right: 1vw;
  display: flex;
  justify-content: center;
}

.draft-table-row {
  text-align: center;
  cursor: pointer;
  height: 2.5vw;
}

.stat-glossary {
  margin: auto;
  margin-bottom: 2vh;
  background: #a4a5a8;
  padding: 1vw;
  border-radius: 1vw;
}

.profile-headshot {
  max-width: 250px;
  max-height: 250px;
  object-fit: contain;
  border-radius: 1vw;
  border-style: solid;
  border-color: black;
}

.profile-headshot-small {
  max-width: 2.5vw;
  max-height: 2.5vw;
  object-fit: contain;
  border-radius: 50%;
  /* border-style: solid;
  border-color: black; */
}

.profile-flag {
  margin-top: 2vh;
  width: 8vw;
  height: 5.3vw;
  max-width: 80px;
  max-height: 53px;
  border-radius: 0.5vw;
}

.athlete-info {
  width: 100%;
  background-size: cover;
}

.loading {
  color: white;
  display: flex;
  justify-content: center;
}

.grow-vert-elo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.grow-vert-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
  margin: 10px;
  padding: 1vw;
  border-radius: 2vw;
  border-style: solid;
  border-width: 1px;
  background: white;
}

.grow-vert-tomoa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 1vw;
  border-radius: 2vw;
  border-style: solid;
  border-width: 1px;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('images/tomoa.jpeg');
  background-size: cover;
  width: 20vw;
  color: white;
  border-color: black;
}

.grow-vert-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 5px; */
}

.grow-vert-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 5px;
}

.rank-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: palevioletred;
  width: 30px;
  height: 30px;
  color: black;
  border-radius: 5px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
}

.rank-number-big {
  text-align: center;
  vertical-align: center;
  align-items: center;
  background-color: palevioletred;
  width: 2.5vw;
  font-size: 1.3vw;
}

.fantasy-final-score {
  background: white;
  color: black;
  font-weight: bold;
  border-radius: 0.5vw;
  padding: 0.2vw;
}

.elo-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  font-size: 20px;
  margin-left: auto;
}

.grow-horiz-elo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  gap: 1.5vw;
}

.grow-horiz-fantasy-card {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.grow-horiz-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 35vw;
  gap: 15px;
  /* padding: 5px 10px; */
  background: steelblue;
  border-radius: 5px;
  transition: 300ms;
}

.grow-horiz-result:hover {
  filter: brightness(1.3);
}

.grow-horiz-space {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  gap: 120px;
}

.grow-horiz-my-team {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 4vw;
}

.search-drop-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border-color: black;
  border-style: solid;
  background: white;
  width: 90px;
  height: 25px;
  gap: 3px;
  cursor: pointer;
}

.stats-drop-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border-color: black;
  border-style: solid;
  background: white;
  width: 15vw;
  height: 25px;
  font-size: 1.8vw;
  font-weight: 800;
  padding: 0.5vw 1vw;
  gap: 3px;
  cursor: pointer;
}

.search-drop-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border-color: black;
  border-style: solid;
  background: white;
  /* width: 90px; */
  height: 25px;
  cursor: pointer;
}

.search-drop-item:hover {
  background: palevioletred;
}

.stat-drop-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border-color: black;
  border-style: solid;
  background: white;
  /* width: 160px; */
  height: 25px;
  /* margin: 0px 10px; */
  cursor: pointer;
}

.stat-drop-item:hover {
  background: palevioletred;
}

.dot-gold {
  /* height: 120px;
  width: 120px; */
  height: 10vw;
  width: 10vw;
  background: rgb(177, 160, 13);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 0px;
  border-style: solid;
  border-color: black;
}

.dot-silver {
  /* height:  100px;
  width: 100px; */
  height: 9vw;
  width: 9vw;
  background: silver;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 40px;
  border-style: solid;
  border-color: black;
}

.dot-bronze {
  /* height:  100px;
  width: 100px; */
  height: 9vw;
  width: 9vw;
  background: rgb(178, 81, 30);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 40px;
  border-style: solid;
  border-color: black;
}

.dot-finals {
  height: 9vw;
  width: 9vw;
  background: salmon;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
  border-style: solid;
  border-color: black;
}

.dot-semifinals {
  height: 9vw;
  width: 9vw;
  background: pink;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
  border-style: solid;
  border-color: black;
}

.dot-elo-b {
  height: 10vw;
  width: 10vw;
  max-height:  100px;
  max-width: 100px;
  background: rgb(53, 173, 163);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
}

.dot-elo-l {
  height: 10vw;
  width: 10vw;
  max-height:  100px;
  max-width: 100px;
  background: rgb(116, 179, 231);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  margin-bottom: 10px;
}

.boulder-elo-card {
  background: rgb(53, 173, 163);
  border-radius: 5px;
  padding: 3px;
  font-size: 18px;
}

.lead-elo-card {
  background: rgb(116, 179, 231);
  border-radius: 5px;
  padding: 3px;
  font-size: 18px;
}

.lead-tree {
  display: flex;
  justify-content: center;
  max-width: 10vw;
  min-width: 6vw;
  margin-top: 30px;
  overflow: auto;
  min-height: 300px;
  padding: 0.5vw;
  border-radius: 1.5vw;
    background-color: rgba(255, 255, 255, 0.712);

}

.event {
  display: flex;
  justify-content: flex-end;
}

.round {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5vw;
}

.insight {
  position: relative;
  display: flex;
  justify-content: center;
  height: 30;
  width: 30;
}

.janja {
  background-image: url('images/janja.jpeg');
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
}

.ifsc-logo {
  width: 4vw;
  height: 4vw;
  max-height: 45px;
  max-width: 45px;
  margin-right: 0.5vw;
  border-radius: 12px;
}

.home {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('images/janja1.jpg');
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center; 
  align-items: flex-start;
  flex-direction: column;
}

.home-text {
  color: white;
  display: flex;
  font-size: 5vw;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-left: 7vw;
}

.home-bottom {
  background-color: black;
  /* width: 100%; */
  /* height: 3020px; */
  display: flex;
  flex-direction: column;
  color: white;
}

.home-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-bottom-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.home-bottom-row-3 {
  background-color: #66686d;
  width: 35vw;
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  transition: 500ms;
}

.home-bottom-row-3:hover {
  filter: brightness(1.3);
  transform: scale(1.1);
}

.home-bottom-text {
  margin-left: 100px;
  width: 250px;
}

.home-bottom-text-2 {
  margin-right: 100px;
  width: 250px;
}

.image-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img-screen {
  width: 800px;
  height: 450px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 150px;
}

.ap1 {
  width: 39vw;
  height: 13vw;
  border-radius: 1vw;
  margin-top: 150px;
}

.ap2 {
  width: 16vw;
  height: 14.8vw;
  border-radius: 1vw;
  margin-top: 2vw;
}

.ap3 {
  width: 14vw;
  height: 14.8vw;
  border-radius: 1vw;
  margin-top: 2vw;
}

.ap4 {
  width: 25.5vw;
  height: 22.8vw;
  border-radius: 1vw;
  margin-top: 2vw;
}

.immaculate {
  width: 27vw;
  height: 42vw;
  border-top-right-radius: 2vw;
  border-bottom-right-radius: 2vw;
  margin-top: 150px;
}

.lead-insights {
  width: 25vw;
  /* height: 40vw; */
  border-top-left-radius: 2vw;
  border-bottom-left-radius: 2vw;
  margin-top: 100px;
}

.lead-tree-img {
  width: 8vw;
  /* height: 600px; */
  border-radius: 2vw;
  margin-top: 150px;
  margin-right: 3vw;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  /* position: fixed; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* height: 8vh; */
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  /* width: 100; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg { 
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}


/* Dropdown Menu */

.dropdown {
  position: fixed;
  top: 58px;
  width: 80px;
  max-height: 200px;
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: scroll;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

.btn {
  display: flex;
  justify-content: center;
  background: steelblue;
  color: white;
  border: none;
  padding: 0.8vw 1.5vw;
  margin: 0.3vw;
  border-radius: 0.8vw;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  transition: filter 300ms;
}

.search-bar-btn {
  display: flex;
  /* justify-content: center; */
  background: steelblue;
  color: white;
  border: none;
  padding: 0.5vw 0.8vw;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  transition: filter 300ms;
}

.search-bar {
  display: flex;
  width: 12vw;
  max-width: 150px;
  padding: 0.8vw 1vw;
  padding-left: 3vw;
  margin: 0.5vw;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
}

.form-input {
  display: flex;
  width: 130px;
  padding: 10px 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
}

.search-dropdown {
  position: fixed;
  top: 58px;
  width: 14vw;
  margin-left: 1.4vw;
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.8vw;
  /* overflow: hidden; */
  transition: height var(--speed) ease;
}

.season-dropdown {
  position: absolute;
  width: 90px;
  max-height: 200px;
  /* background-color: var(--bg); */
  background-color: rgb(92, 88, 88);
  border-radius: var(--border-radius);
  padding: 3px;
  overflow: scroll;
}

.stat-dropdown {
  position: sticky;
  width: 17vw;
  max-height: 20vh;
  /* background-color: var(--bg); */
  background-color: rgb(92, 88, 88);
  border-radius: var(--border-radius);
  padding: 3px;
  overflow: scroll;
}

.btn:hover {
  filter: brightness(1.3);
}

.search-bar-btn:hover {
  filter: brightness(1.3);
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.link-text {
  /* background-color: rgba(136, 133, 133, 0.2); */
  border-radius: 8px;
  font-size: 3vw;
}

.route-background {
  background-color: rgb(160, 154, 154);
  border-radius: 8px;
}

.ath-link-text {
  color: white;
}

.ath-link-text:hover {
  text-decoration: underline;
}

.grow-horiz-round {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link-active {
  transform: scale(1.2);
}

.w-link {
  background: palevioletred;
  margin: 2px;
  cursor: pointer;
  transition: filter 400ms;
  height: 5vw;
  width: 5vw;
  max-width: 50px;
  max-height: 50px;
  color: white;
  border-radius: 30%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2vw;
}

.w-link:hover {
  transform: scale(1.2);
  filter: brightness(1.2);
}

.m-link:hover {
  transform: scale(1.2);
  filter: brightness(1.2);
}

.m-link {
  background: steelblue;
  margin: 2px;
  cursor: pointer;
  transition: filter 400ms;
  height: 5vw;
  width: 5vw;
  max-width: 50px;
  max-height: 50px;
  color: white;
  border-radius: 30%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 2vw;
}

.fantasy-tab:hover {
  /* transform: scale(1.1); */
  filter: brightness(1.2);
}

.fantasy-tab {
  background: steelblue;
  margin: 0.4vw;
  cursor: pointer;
  transition: filter 400ms;
  padding: 0.7vw 1.5vw;
  color: white;
  border-radius: 1vw;
  justify-content: center;
  display: flex;
  align-items: center;
  /* font-size: 25px; */
}

.athlete-score-text {
  font-size: 20;
}

.athlete-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: solid;
  padding: 1vh 1.5vw;
  margin: 5px;
  border-radius: 10px;
  /* text-decoration: none; */
  font-size: 1.15vw;
}

.event-card{
  background: linear-gradient(steelblue, palevioletred);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.event-card:hover {
  filter: brightness(0.8);
}

.dim {
  background: rgba(0, 0, 0, 0.3);
}

.Moscow21b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/moscow_21_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.Moscow21b:hover {
  filter: brightness(0.8);
}

.MeiringenSUI21b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/MeiringenSUI_21_b.jpg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MeiringenSUI21b:hover {
  filter: brightness(0.8);
}

.SaltLakeCityUSA121b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/SaltLakeCityUSA1_21_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.SaltLakeCityUSA121b:hover {
  filter: brightness(0.8);
}

.SaltLakeCityUSA221b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/SaltLakeCityUSA2_21_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.SaltLakeCityUSA221b:hover {
  filter: brightness(0.8);
}

.InnsbruckAUT21b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/InnsbruckAUT_21_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InnsbruckAUT21b:hover {
  filter: brightness(0.8);
}

.MeiringenSUI19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/meiringenSUI_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MeiringenSUI19b:hover {
  filter: brightness(0.8);
}

.MoscowRUS19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/moscowRUS_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MoscowRUS19b:hover {
  filter: brightness(0.8);
}

.ChongqingCHN19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chongqingCHN_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChongqingCHN19b:hover {
  filter: brightness(0.8);
}

.WujiangCHN19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/wujiangCHN_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.WujiangCHN19b:hover {
  filter: brightness(0.8);
}

.MunichGER19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/munichGER_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MunichGER19b:hover {
  filter: brightness(0.8);
}

.VailUSA19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/vailUSA_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VailUSA19b:hover {
  filter: brightness(0.8);
}

.HachiojiJPN19b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/hachiojiJPN_19_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.HachiojiJPN19b:hover {
  filter: brightness(0.8);
}

.MeiringenSUI18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/meiringenSUI_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MeiringenSUI18b:hover {
  filter: brightness(0.8);
}

.MoscowRUS18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/moscowRUS_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MoscowRUS18b:hover {
  filter: brightness(0.8);
}

.ChongqingCHN18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chongqingCHN_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChongqingCHN18b:hover {
  filter: brightness(0.8);
}

.Tai\'anCHN18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/taianCHN_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.Tai\'anCHN18b:hover {
  filter: brightness(0.8);
}

.HachiojiJPN18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/hachiojiJPN_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.HachiojiJPN18b:hover {
  filter: brightness(0.8);
}

.VailUSA18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/vailUSA_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VailUSA18b:hover {
  filter: brightness(0.8);
}

.MunichGER18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/munichGER_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MunichGER18b:hover {
  filter: brightness(0.8);
}

.InnsbruckAUT18b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/innsbruckAUT_18_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InnsbruckAUT18b:hover {
  filter: brightness(0.8);
}

.XiamenCHN18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/xiamenCHN_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.XiamenCHN18l:hover {
  filter: brightness(0.8);
}

.KranjSLO18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kranjSLO_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KranjSLO18l:hover {
  filter: brightness(0.8);
}

.KranjSLO19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kranjSLO_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KranjSLO19l:hover {
  filter: brightness(0.8);
}

.KranjSLO21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kranjSLO_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KranjSLO21l:hover {
  filter: brightness(0.8);
}

.ArcoITA18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/arcoITA_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ArcoITA18l:hover {
  filter: brightness(0.8);
}

.InnsbruckAUT18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/innsbruckAUT_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InnsbruckAUT18l:hover {
  filter: brightness(0.8);
}

.BrianconFRA18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA18l:hover {
  filter: brightness(0.8);
}

.ChamonixFRA18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chamonixFRN_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChamonixFRA18l:hover {
  filter: brightness(0.8);
}

.VillarsSUI18l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/villarsSUI_18_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VillarsSUI18l:hover {
  filter: brightness(0.8);
}

.InzaiJPN19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/inzaiJPN_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InzaiJPN19l:hover {
  filter: brightness(0.8);
}

.XiamenCHN19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/xiamenCHN_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.XiamenCHN19l:hover {
  filter: brightness(0.8);
}

.HachiojiJPN19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/hachiojiJPN_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.HachiojiJPN19l:hover {
  filter: brightness(0.8);
}

.BrianconFRA19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA19l:hover {
  filter: brightness(0.8);
}

.ChamonixFRA19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chamonixFRA_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChamonixFRA19l:hover {
  filter: brightness(0.8);
}

.VillarsSUI19l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/villarsSUI_19_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VillarsSUI19l:hover {
  filter: brightness(0.8);
}

.BrianconFRA20l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_20_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA20l:hover {
  filter: brightness(0.8);
}

.BrianconFRA21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA21l:hover {
  filter: brightness(0.8);
}

.ChamonixFRA21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chamonixFRA_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChamonixFRA21l:hover {
  filter: brightness(0.8);
}

.VillarsSUI21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/villarsSUI_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VillarsSUI21l:hover {
  filter: brightness(0.8);
}

.InnsbruckAUT21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/innsbruckAUT_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InnsbruckAUT21l:hover {
  filter: brightness(0.8);
}

.Moscow21l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/moscow_21_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.Moscow21l:hover {
  filter: brightness(0.8);
}

.VillarsSUI17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/villlarsSUI_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VillarsSUI17l:hover {
  filter: brightness(0.8);
}

.ArcoITA17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/arcoITA_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.EdinburghGBR17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/edinburghGBR_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.EdinburghGBR17l:hover {
  filter: brightness(0.8);
}

.ArcoITA17l:hover {
  filter: brightness(0.8);
}

.WujiangCHN17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/wujiangCHN_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.WujiangCHN17l:hover {
  filter: brightness(0.8);
}

.KranjSLO17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kranjSLO_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KranjSLO17l:hover {
  filter: brightness(0.8);
}

.ChamonixFRA17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chamonixFRA_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChamonixFRA17l:hover {
  filter: brightness(0.8);
}

.BrianconFRA17l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_17_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA17l:hover {
  filter: brightness(0.8);
}

.MeiringenSUI17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/meiringenSUI_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MeiringenSUI17b:hover {
  filter: brightness(0.8);
}

.ChongqingCHN17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chongqingCHN_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChongqingCHN17b:hover {
  filter: brightness(0.8);
}

.NanjingCHN17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/nanjingCHN_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.NanjingCHN17b:hover {
  filter: brightness(0.8);
}

.Hachioji\,TokyoJPN17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/hachioji\,TokyoJPN_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.Hachioji\,TokyoJPN17b:hover {
  filter: brightness(0.8);
}

.VailUSA17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/vailUSA_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VailUSA17b:hover {
  filter: brightness(0.8);
}

.NaviMumbaiIND17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/navimumbaiIND_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.NaviMumbaiIND17b:hover {
  filter: brightness(0.8);
}

.MunichGER17b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/munichGER_17_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MunichGER17b:hover {
  filter: brightness(0.8);
}

.XiamenCHN16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/xiamenCHN_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.XiamenCHN16l:hover {
  filter: brightness(0.8);
}

.KranjSLO16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kranjSLO_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KranjSLO16l:hover {
  filter: brightness(0.8);
}

.ParisFRA16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/parisFRA_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ParisFRA16l:hover {
  filter: brightness(0.8);
}

.ArcoITA16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/arcoITA_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ArcoITA16l:hover {
  filter: brightness(0.8);
}

.ImstAUT16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/imstAUT_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ImstAUT16l:hover {
  filter: brightness(0.8);
}

.BrianconFRA16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/brianconFRA_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.BrianconFRA16l:hover {
  filter: brightness(0.8);
}

.VillarsSUI16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/villarsSUI_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VillarsSUI16l:hover {
  filter: brightness(0.8);
}

.ChamonixFRA16l{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chamonixFRA_16_l.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChamonixFRA16l:hover {
  filter: brightness(0.8);
}

.ParisFRA16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/parisFRA_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ParisFRA16b:hover {
  filter: brightness(0.8);
}

.MunichGER16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/munichGER_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MunichGER16b:hover {
  filter: brightness(0.8);
}

.VailUSA16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/vailUSA_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.VailUSA16b:hover {
  filter: brightness(0.8);
}

.InnsbruckAUT16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/innsbruckAUT_16_b.png);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.InnsbruckAUT16b:hover {
  filter: brightness(0.8);
}

.NaviMumbaiIND16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/navimumbaiIND_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.NaviMumbaiIND16b:hover {
  filter: brightness(0.8);
}

.ChongqingCHN16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/chongqingCHN_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.ChongqingCHN16b:hover {
  filter: brightness(0.8);
}

.KazoJPN16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/kazoJPN_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.KazoJPN16b:hover {
  filter: brightness(0.8);
}

.MeiringenSUI16b{
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(images/meiringenSUI_16_b.jpeg);
  background-size: cover;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  
  transition: filter 300ms;
  width: 40vw;
  height: 8vw;
  margin: 5px;
}

.MeiringenSUI16b:hover {
  filter: brightness(0.8);
}

.title {
  margin-right: auto;
  color: white;
  cursor: pointer;
  font-size: min(40px, 4vw);
  display: flex;
  align-items: center;
}

.event-title {
  display: flex;
  border: none;
  color: white;
  cursor: pointer;
  background: none;
  font-size: 4vw;
  font-weight: 600;
  padding-top: 1vh;
}

.background {
  background-image: url("images/janja.jpeg");
  position: "fixed";
  top: 0;
  width: "100%";
  height: "100%";
  background-size: "cover";
}

table, th, td {
  /* border: 1px solid white; */
  border-collapse: collapse;
  padding-left: 0.9vw;
  padding-right: 0.9vw;
}

table {
  display: block;
  max-height: 70vh;
  overflow: scroll;
}

thead {
  position: sticky;
  top: 0;
  font-weight: 900;
  /* text-decoration: underline; */
}

.dot-rating {
  height: 10vw;
  width: 10vw;
  max-height: 125px;
  max-width: 125px;
  background: rgb(238, 215, 83);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 3.5vw;
  transition: 800ms;
  margin-left: 8vw;
  margin-top: 15px;
  border-style: solid;
  border-color: red;
}

.dot-rating-little {
  height: 20px;
  width: 20px;
  background: rgb(238, 215, 83);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  /* font-size: 40px; */
  margin-right: 5px;
  margin-bottom: 5px;
}

.dot-rating:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}

.dot-top {
  height:  10vw;
  width: 10vw;
  max-width: 100px;
  max-height: 100px;
  background: rgb(116, 179, 231);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 3vw;
  transition: 800ms;
}

.dot-top:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}

.dot-top-little {
  height: 20px;
  width: 20px;
  background: rgb(116, 179, 231);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.dot-tree {
  height: 10px;
  /* width: 10px; */
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  background: green;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.dot-zone {
  height: 10vw;
  width: 10vw;
  max-height:  100px;
  max-width: 100px;
  background: rgb(53, 173, 163);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 3vw;
  transition: 800ms;
}

.dot-zone:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}

.dot-stop {
  height: 10vw;
  width: 10vw;
  max-height:  100px;
  max-width: 100px;
  border-radius: 50%;
  justify-content: center;
  border-style: solid;
  display: flex;
  align-items: center;
  font-size: 3vw;
  transition: 800ms;
  margin-bottom: 10px;
}

.dot-stop:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}

.dot-zone-little {
  height: 20px;
  width: 20px;
  background: rgb(53, 173, 163);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-right: 5px;
}

.dot-att {
  height: 2.5vw;
  width: 2.5vw;
  background: white;
  color: black;
  border-radius: 30%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 600;
}

.dot-att-lead {
  height: 4vw;
  width: 4vw;
  background: white;
  color: black;
  border-radius: 30%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 600;
}


.grow-vert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}

.grow-vert-events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}


.auth-field-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 12px;
}

.error-message {
  text-align: center;
  justify-content: center;
  color: red;
  font-size: 12px;
}

.grow-vert-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 5px; */
}

.grow-tree {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.grow-horiz {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.grow-horiz-ends {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20vw;
}

.grow-horiz-events {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.grow-horiz-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  /* justify-content: space-around; */
}

.grow-horiz-tree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.inline {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.inline-legend {
  display: flex;
}


.triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid rgb(197, 12, 12);
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 40px;
  transition: 800ms;
  border-radius: 10px;
}

.triangle:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}